class AppHeader extends HTMLElement {
  constructor() {
    super();
  }

  async connectedCallback() {
    try {
      const htmlFragmentPath = '/fragments/common/appHeader.html';

      const html = await fetch(htmlFragmentPath).then(response => response.text());
      this.innerHTML = html

    } catch (error) {
      console.error('Error loading template:', error);
    }
  }
}

export default AppHeader
