const resumeFormTemplate = `
<p>Welcome to JobClutch! Please use this tool to customize your resume to leverage keywords from the job description your are targeting.</p>
  <form id="appForm" action="" method="get" class="container">
    <!-- Job Description Field -->
    <div class="row mb-3">
      <div class="col-12">
        <label for="jobDescriptionText">Job Description (In Plain Text)</label><br />
        <textarea id="jobDescriptionText" name="jobDescriptionText" rows="15" cols="50" class="col-12"></textarea><br />
        <input id="submitJobDescription" type="submit" value="Submit Job Description" /><br />
      </div>
    </div>

    <!-- Keywords Field -->
    <div class="row mb-3">
      <div class="col-12">
        <label for="jobDescriptionKeywords">Keywords</label><br />
        <!-- Spinner -->
        <div id="jobDescriptionKeywordsSpinner" class="spinner-border text-primary" role="status" style="display: none;">
          <span class="visually-hidden">Loading...</span>
        </div>
        <!-- Textarea -->
        <textarea id="jobDescriptionKeywords" name="jobDescriptionKeywords" rows="15" cols="50" class="col-12"></textarea><br />
      </div>
    </div>

    <!-- Original Resume Field -->
    <div class="row">
      <div id="originalResume" class="col-md-6 mb-3">
        <label for="resumeText">Resume (In Plain Text)</label><br/>
        <textarea id="resumeText" name="resumeText" rows="15" cols="50" class="col-md-12"></textarea><br />

        <input id="submitResume" type="submit" value="Submit Resume" />
      </div>

      <!-- Rewritten Resume Field -->
      <div id="rewrittenResume" class="col-md-6 mb-3 ml-auto">
        <label for="rewrittenResumeText">Rewritten Resume</label><br/>
        <!-- Spinner -->
        <div id="rewrittenResumeSpinner" class="spinner-border text-primary" role="status" style="display: none;">
          <span class="visually-hidden">Loading...</span>
        </div>
        <textarea id="rewrittenResumeText" name="rewrittenResumeText" rows="15" cols="50" class="col-md-12"></textarea><br />
      </div>
    </div>

    <!-- Changes -->
    <div class="row mb-3">
      <div class="col-12">
        <label for="resumeChanges">Resume Changes</label><br />
        <!-- Spinner -->
        <div id="resumeChangesSpinner" class="spinner-border text-primary" role="status" style="display: none;">
          <span class="visually-hidden">Loading...</span>
        </div>
        <!-- Textarea -->
        <textarea id="resumeChangesText" name="resumeChangesText" rows="15" cols="50" class="col-12"></textarea><br />
      </div>
    </div>

  </form>
`

class ResumeForm extends HTMLElement {
  connectedCallback() {
    this.innerHTML = resumeFormTemplate;

    // Form logic
    const jobDescriptionText = document.getElementById('jobDescriptionText')

    const jobDescriptionKeywords = document.getElementById('jobDescriptionKeywords');
    const jobDescriptionKeywordsSpinner = document.getElementById('jobDescriptionKeywordsSpinner');

    const resumeText = document.getElementById('resumeText')

    const rewrittenResumeSpinner = document.getElementById('rewrittenResumeSpinner')
    const rewrittenResumeText = document.getElementById('rewrittenResumeText')

    const resumeChangesSpinner = document.getElementById('resumeChangesSpinner');
    const resumeChangesText = document.getElementById('resumeChangesText');

    const submitJobDescriptionBtn = document.getElementById('submitJobDescription')
    const submitResumeBtn = document.getElementById('submitResume')

    submitJobDescriptionBtn.addEventListener('click', async function(event) {
      event.preventDefault();

      jobDescriptionKeywordsSpinner.style.display = 'block';
      jobDescriptionKeywords.style.display = 'none';

      const getKeywordsMutationInput = {
        jobDescription: jobDescriptionText.value
      }

      const gqlGetKeywordsMutation = `
      mutation GetKeywords($jobDescription: String!) {
        getKeywordsForJobDescription(jobDescription:$jobDescription) {
          keywords
        }
      }
      `

      const response = await fetch("https://dev.resume.jobclutch.app/graphql", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: gqlGetKeywordsMutation,
          variables: getKeywordsMutationInput,
        })
      })
        
      const result = await response.json();
      const keywords = result.data.getKeywordsForJobDescription.keywords
      
      let keywordsText = "";

      for (let i = 0; i < keywords.length; i++) {
        keywordsText += `${keywords[i]}\n`;
      }

      jobDescriptionKeywords.value = keywordsText;
      jobDescriptionKeywordsSpinner.style.display = 'none';
      jobDescriptionKeywords.style.display = 'block';
    });

    submitResumeBtn.addEventListener('click', async function(event) {
      event.preventDefault();

      rewrittenResumeSpinner.style.display = 'block';
      rewrittenResumeText.style.display = 'none';
    
      resumeChangesSpinner.style.display = 'block';
      resumeChangesText.style.display = 'none';

      // TODO: Refactor
      const keywordsText = jobDescriptionKeywords.value;
      const keywords = keywordsText.split("\n");

      const rewriteResumeMutationInput = {
        keywords: keywords,
        resumeText: resumeText.value,
      }

      const gqlRewriteResumeMutation = `
      mutation RewriteResume($resumeText: String!, $keywords: [String!]!) {
        rewriteResume(resumeText:$resumeText, keywords: $keywords) {
          resume
          changeRationale
        }
      }
      `

      const response = await fetch("https://dev.resume.jobclutch.app/graphql", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: gqlRewriteResumeMutation,
          variables: rewriteResumeMutationInput,
        })
      })
        
      const result = await response.json();
      const rewrittenResume = result.data.rewriteResume.resume;
      const changeRationale = result.data.rewriteResume.changeRationale;

      rewrittenResumeText.value = rewrittenResume
      rewrittenResumeSpinner.style.display = 'none';
      rewrittenResumeText.style.display = 'block';

      resumeChangesText.value = changeRationale;
      console.log(changeRationale);
      resumeChangesSpinner.style.display = 'none';
      resumeChangesText.style.display = 'block';
    });
  }
}

export default ResumeForm;
