import { Router } from '@vaadin/router';

import { AppFooter, AppHeader } from "../components/common";
import { LoginPage } from "../components/loginPage";
import { HomePage } from "../components/features";
import { ResumeForm } from "../components/resumeForm";

// Registering custom components
customElements.define('app-header', AppHeader);
customElements.define('login-page', LoginPage);
customElements.define('resume-form', ResumeForm);
customElements.define('home-page', HomePage);
customElements.define('app-footer', AppFooter);


document.addEventListener('DOMContentLoaded', async function () {
  console.log(document.cookie);

  const response = await fetch('https://dev.auth.jobclutch.app/validate', {
    method: 'POST',
    credentials: 'include'
  })
  const result = await response.json();
  console.log(result);

  // const path = window.location.pathname;
  // const pathSegments = path.split('/');
  // const slug = pathSegments.pop() || pathSegments.pop();
  // console.log('slug: ', slug);

  // if (!response.ok && slug == ''){
  //   console.log("Validation failed; slug is empty (i.e., we are at /)")
  // } else if (!response.ok && slug == 'resume') {
  //   window.location.href = '/';
  // } else if (response.ok) {
  //   window.location.href = '/resume';
  // }

  let isAuthenticated = false;
  if (response.ok) {
    isAuthenticated = true;
  }

  const outlet = document.getElementById('outlet');
  const router = new Router(outlet);
  router.setRoutes([
    {
      path: '/',
      component: 'home-page',
      action: (context, commands) => {
        if (isAuthenticated) {
          return commands.redirect('/resume');
        }
      }
    },
    {
      path: '/resume',
      component: 'resume-form',
      action: (context, commands) => {
        if (!isAuthenticated) {
          return commands.redirect('/');
        }
      }
    },
    {
      path: '/login',
      component: 'login-page',
      action: (context, commands) => {
        if(isAuthenticated) {
          return commands.redirect('/resume')
        }
      }
    }
  ])

    
  
});
