const loginPageTemplate = `
<h2>Login</h2>

<form id="mailingListForm" action="" method="get" class="container">
  <div class="row mb-3">
    <div class="col-12">
      <label for="loginEmailInput">Email Address: </label>
      <input id="loginEmailInput" type="text"><br />
      <label for="loginPasswordInput">Pasword: </label>
      <input id="loginPasswordInput" type="password">
      <input id="submitLogin" type="submit" value="Login" />
    </div>
  </div>
</form>
`

class LoginPage extends HTMLElement {
  connectedCallback() {
    this.innerHTML = loginPageTemplate;

    // Form logic
    const email = document.getElementById('loginEmailInput');
    const password = document.getElementById('loginPasswordInput');
    const submitLoginBtn = document.getElementById('submitLogin');

    submitLoginBtn.addEventListener('click', async function(event) {
      event.preventDefault();

      const loginResponse = await fetch("https://dev.auth.jobclutch.app/login", {
        method: "POST",
        credentials: 'include',
        headers: {
          "Origin": "https://dev.jobclutch.app",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email.value,
          password: password.value
        })
      });

      // function getCookie(name) {
      //   const value = `; ${document.cookie}`;
      //   const parts = value.split(`; ${name}=`);
        
      //   if (parts.length === 2) return parts.pop().split(';').shift();
      // }
      console.log("loginResponse:");
      console.log(loginResponse);
      console.log(document.cookie);
      // const token = getCookie('jwt_token');
      

      const validateResponse = await fetch('https://dev.auth.jobclutch.app/validate', {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Origin": "https://dev.jobclutch.app",
        },
      })
      const result = await validateResponse.json();
      console.log(result);

      // const result = await response.json();
      // console.log(result);
      // console.log(document.cookie);
      window.location.href = "/"
    })
  }
}

export default LoginPage;
