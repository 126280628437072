class AppFooter extends HTMLElement {
  constructor() {
    super();
  }
  
  async connectedCallback() {
    try {
      const htmlFragmentPath = '/fragments/common/appFooter.html';
      const html = await fetch(htmlFragmentPath).then(response => response.text());
      this.innerHTML = html;
    } catch (error) {
      console.error('Error loading template: ', error);
    }
  }
}

export default AppFooter
